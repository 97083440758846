import React, { Fragment } from 'react';
import Heading from "../uiStyle/Heading";
import TrendingNewsSlider from "../TrendingNewsSlider";
import { Link } from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";

import transm1 from '../../doc/img/trending/transm1.jpg';
import transm2 from '../../doc/img/trending/transm2.jpg';
import transm4 from '../../doc/img/trending/transm4.jpg';
import transm5 from '../../doc/img/trending/transm5.jpg';
import transm6 from '../../doc/img/trending/transm6.jpg';

const trendingNews = [
    {
        image: transm1,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'Nancy Zhang a Chinese busy woman and Dhaka'
    },
    {
        image: transm2,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'U.S. Response subash says he will label regions by risk of…'
    },
    {
        image: transm4,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'Venezuela elan govt and opposit the property collect'
    },
    {
        image: transm5,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'Nancy Zhang a Chinese busy woman and Dhaka'
    },
    {
        image: transm6,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'U.S. Response subash says he will label regions by risk of…'
    },
    {
        image: transm4,
        category: 'TECHNOLOGY',
        date: 'March 26, 2020',
        title: 'Venezuela elan govt and opposit the property collect'
    },
];

function formatDate(dateStr) {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" });
    return formattedDate;
}

const TrendingNews = ({ dark, data }) => {
    return (
        <Fragment>
            <Heading title="Trending News" />
            <TrendingNewsSlider data={data} />
            {dark ? <div className="border_white" /> : <div className="border_black" />}
            <div className="space-30" />
            <div className="row">
                {data?.length > 0 && <div className="col-lg-6">
                    {data.slice(0, 3).map((item, i) => (
                        <Fragment key={i}>
                            <div className="single_post widgets_small">
                                <div className="post_img">
                                    <div className="img_wrap">
                                    <Link to={`/post/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`} ><img style={{width:100,height:77}} src={item?.attributes?.url} alt="thumb" /></Link>
                                    </div>
                                    {/* <span className="tranding">
                                        <FontAwesome name="bolt" />
                                    </span> */}
                                </div>
                                <div className="single_post_text">
                                    <div className="meta2">
                                        <Link to={`/category/${item?.attributes?.categoryTitle?.replaceAll(" ","-")}/${item?.attributes?.category_id}`} >{item?.attributes?.categoryTitle || ""}</Link>
                                        <Link to="/">{formatDate(item?.attributes?.publishedOn ? item?.attributes?.publishedOn : item?.attributes?.createdAt)}</Link>
                                    </div>
                                    <h4><Link to={`/post/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`} >{item.attributes?.title}</Link></h4>
                                </div>
                            </div>
                            <div className="space-15" />
                            {dark ? <div className="border_white" /> : <div className="border_black" />}
                            <div className="space-15" />
                        </Fragment>
                    ))}
                </div>}
                {data?.length > 0 && <div className="col-lg-6">
                    {data.slice(3, 6).map((item, i) => (
                        <Fragment key={i}>
                            <div className="single_post widgets_small">
                                <div className="post_img">
                                    <div className="img_wrap">
                                    <img style={{width:100,height:77}} src={item?.attributes?.url} alt="thumb" />
                                    </div>
                                    {/* <span className="tranding">
                                        <FontAwesome name="bolt" />
                                    </span> */}
                                </div>
                                <div className="single_post_text">
                                    <div className="meta2">
                                        <Link to={`/category/${item?.attributes?.categoryTitle?.replaceAll(" ","-")}/${item?.attributes?.category_id}`} >{item?.attributes?.categoryTitle || ""}</Link>
                                        <Link to="/">{formatDate(item?.attributes?.publishedOn ? item?.attributes?.publishedOn : item?.attributes?.createdAt)}</Link>
                                    </div>
                                    <h4><Link className='post-ellipse' to={`/post/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`}>{item.attributes?.title}</Link></h4>
                                </div>
                            </div>
                            <div className="space-15" />
                            {dark ? <div className="border_white" /> : <div className="border_black" />}
                            <div className="space-15" />
                        </Fragment>
                    ))}
                </div>
}
            </div>
        </Fragment>
    );
};

export default TrendingNews;