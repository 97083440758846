import React, { Fragment, useState, useEffect } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import { Helmet } from "react-helmet";
import "./style.scss";

import Logobrand from "../../assets/img/brands/image.png";
import NominationForm from "./nominationForm";
import union from "../../doc/img/icon/union.png";
const menuItems = [
  "About",
  "Process of Selection",
  "Nomination Process",
  "Nominate Now",
  "Awards Ceremony",
  "Prestigious League",
];

const contentItems = [
  "About",
  "Process of Selection",
  "Nomination Process",
  "Nominate Now",
  "Awards Ceremony",
  "Prestigious League",
];

const BrandsPage = () => {
  const [popupModal, setPopupModal] = useState(false);

  const handleMenuClick = (index, value) => {
    if (value[index] == "Nominate Now") {
      //setPopupModal(true);
      const element = document.getElementById(`content-${index}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      const element = document.getElementById(`content-${index}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const openModal = () => {
    setPopupModal(!popupModal);
  };
  return (
    <Fragment>
      <Helmet>
        <title>Repute - Brands of repute</title>
        <meta
          name="description"
          content="Repute is a business media firm established to bring together entities of repute."
        />
      </Helmet>
      <BreadCrumb className="shadow5" title="Brands of repute">
        <Fragment>
          <div className="mt-4" />
          <div className="row content-style">
            <div className="col-lg-4 brands">
              <img
                src={Logobrand}
                alt="category"
                style={{ marginLeft: "2rem" }}
              />
              <div className="widget category mb30">
                <ul>
                  {menuItems?.map((item, i) => (
                    <li key={i} onClick={() => handleMenuClick(i, menuItems)}>
                      <div
                        className="brand-link"
                        to={`/category/${item?.attributes?.title?.replaceAll(
                          " ",
                          "-"
                        )}/${item}`}
                        style={{
                          background: `url(${require(`../../assets/img/brands/${
                            i % 2 ? "cropped_image.png" : "category2.jpg"
                          }`)}`,
                        }}
                      >
                        {" "}
                        <span>{item}</span>
                        <img src={union} alt="category" />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>             
            </div>
            <div className="col-lg-8">
              {contentItems.map((content, index) => (
                <div
                  key={index}
                  id={`content-${index}`}
                  className="content-section-brand"
                >
                  {content == "About" && (
                    <div className="row">
                      <h3
                        style={{ marginLeft: "0.7rem" }}
                        className="topic-brands-section"
                      >
                        {menuItems[index]}
                      </h3>
                      <div className="col-12">
                        <div className="author_contentt">
                          <div className="content_1">
                            CELEBRATING EXCELLENCE: INTRODUCING BRANDS OF REPUTE
                            AWARDS
                          </div>
                        </div>
                        <p>
                          Welcome to Brands of Repute, where excellence meets
                          recognition. These awards are dedicated to honoring
                          outstanding brands that have made significant
                          contributions to their respective industries.{" "}
                        </p>
                        <br />

                        <p>
                          At Brands of Repute, we are looking to honor and
                          acknowledge the contributions of brands that spell
                          excellence through their exemplary service, product
                          specialization, innovation and above all consumer
                          centric thought process. The Brands of Repute honor
                          shall inspire and motivate entities gain further
                          repute and set new benchmarks for themselves and the
                          ecosystem.
                        </p>
                        <br />
                        <p className="mb-3">
                          <b>Key Highlights</b>
                        </p>
                        <ul className="">
                          <li>
                            Opportunity for entities to gain recognition and
                            enhance their reputation
                          </li>
                          <li>
                            omination for excellence across various industries
                          </li>
                          <li>Rigorous Selection Process</li>
                          <li>Prestigious Awards Ceremony to Honor Winners</li>
                          <li>PR program for discerning “Brands of Repute”</li>
                        </ul>
                      </div>
                    </div>
                  )}

                  {content == "Process of Selection" && (
                    <div className="row">
                      <h3
                        style={{ marginLeft: "0.7rem" }}
                        className="topic-brands-section"
                      >
                        {menuItems[index]}
                      </h3>
                      <div className="col-12">
                        <div className="author_contentt">
                          <div className="content_1">Selection Criteria:</div>
                        </div>
                        <p>
                          Brands of Repute applies a crafted selection process
                          to ensure every nomination is given a fair and
                          transparent tabulation. Our criteria includes but not
                          limited to :{" "}
                        </p>
                        <br />
                        <ul className="">
                          <li>Market Fitment, Outreach and Impact</li>
                          <li>Product or Service Innovation</li>
                          <li>Customer Feedback and Testimonial</li>
                          <li>Brand Integrity and consumer loyalty</li>
                        </ul>
                        <div className="content_1">Verification Process </div>
                        <p>
                          To ensure we maintain the integrity of the awards, all
                          nominations shall undergo a thorough process of
                          verification by an independent assessment agency to
                          help validate the authenticity of the information
                          provided. This is done to ensure only genuine and
                          deserving brands are considered for these awards.{" "}
                        </p>
                        <br />
                      </div>
                    </div>
                  )}
                  {content == "Nominate Now" && (
                    <div className="row">
                      {/* <h3
                        style={{ marginLeft: "0.7rem" }}
                        className="topic-brands-section"
                      >
                        {menuItems[index]}
                      </h3> */}
                    </div>
                  )}
                  {content == "Nomination Process" && (
                    <div className="row">
                      <h3
                        style={{ marginLeft: "0.7rem" }}
                        className="topic-brands-section"
                      >
                        {menuItems[index]}
                      </h3>
                      <div className="col-12">
                        <div className="author_contentt">
                          <div className="content_1">
                            Shine a Spotlight on Your Brand
                          </div>
                          <div className="content_1">How to Nominate</div>
                        </div>
                        <p>
                          Nominating your brand for the Brands of Repute awards
                          is a simple and straightforward process. Follow these
                          steps{" "}
                        </p>
                        <br />
                        <ul className="">
                          <li>
                            Visit our official website www.therepute.in and
                            navigate to the "Brands of Repute Nomination"
                            section.
                          </li>
                          <li>
                            Fill out the nomination form with accurate and
                            detailed information about your brand.
                          </li>
                          <li>
                            Pay the assessment fee and submit the qualification
                            form.
                          </li>
                          <li>
                            Once payment has been received by us, you will need
                            to provide supporting documentation and testimonials
                            to strengthen your nomination.
                          </li>
                          <li>
                            Submit your nomination before the deadline for
                            consideration by our selection panel.
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}

                  {content == "Awards Ceremony" && (
                    <div className="row">
                      <h3
                        style={{ marginLeft: "0.7rem" }}
                        className="topic-brands-section"
                      >
                        {menuItems[index]}
                      </h3>
                      <div className="col-12">
                        <div className="author_contentt">
                          <div className="content_1">
                            Awards Ceremony and Beyond
                          </div>
                          <div className="content_1">
                            Celebrating Success and Inspiring Excellence
                          </div>
                        </div>
                        <p>
                          The culmination of the Brands of Repute awards program
                          is the prestigious awards ceremony, where winners are
                          felicitated and celebrated for their achievements. The
                          event brings together industry leaders, influencers,
                          and stakeholders to honor excellence and inspire
                          future endeavors.{" "}
                        </p>
                        <br />
                        <div className="content_1">Benefits of Winning</div>
                        <ul className="">
                          <li>Enhanced brand visibility and credibility</li>
                          <li>Recognition from industry peers and experts</li>
                          <li>Increased customer trust and loyalty</li>
                          <li>
                            Opportunities for networking and collaboration
                          </li>
                        </ul>
                        <p>
                          <b>Note :</b>Additional participation fee for the
                          Awards Gala will apply and you will be notified about
                          the same should your entry be selected..{" "}
                        </p>
                        <br />
                      </div>
                    </div>
                  )}

                  {content == "Prestigious League" && (
                    <div className="row">
                      <h3
                        style={{ marginLeft: "0.7rem" }}
                        className="topic-brands-section"
                      >
                        {menuItems[index]}
                      </h3>
                      <div className="col-12">
                        <div className="author_contentt">
                          <div className="content_1">
                            Join the Prestigious League
                          </div>
                        </div>
                        <p>
                          Nominate your brand for the “Brands of Repute” awards
                          today and join the prestigious league of industry
                          leaders and innovators. Celebrate your success and
                          inspire others to reach new heights of excellence{" "}
                        </p>
                        <br />
                      </div>
                      <div className="col-12">
                        <div className="author_contentt">
                          <div className="content__1_">
                            <b>
                              For Nomination & Sponsorship queries please email:{" "}
                            </b>
                          </div>
                          <div className="content__1_">
                            <div>editorial@therepute.in</div>
                            <div>
                              <b>Riaz :</b> +919972677429 <br />
                              Director – Partnerships & Alliances
                            </div>
                          </div>
                        </div>

                        <br />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* <div className="row content-style">
                        <div className="col-12">
                            <div className="author_about">
                                <div className="author_img">
                                    <div className="author_wrap">
                                        <img src={FavIcon} alt="author1" />
                                    </div>
                                </div>
                                <div className="author_contentt" >
                                    <div className='content_1'>CELEBRATING EXCELLENCE: INTRODUCING BRANDS OF REPUTE AWARDS</div>
                              
                                   
                                </div><p>Welcome to Brands of Repute, where excellence meets recognition. These awards are dedicated to honoring outstanding brands that have made significant contributions to their respective industries. </p>
                                <br />                          

                                <p>At Brands of Repute, we are looking to honor and acknowledge the contributions of brands that spell excellence through their exemplary service, product specialization, innovation and above all consumer centric thought process. The Brands of Repute honor shall inspire and motivate entities gain further repute and set new benchmarks for themselves and the ecosystem.</p>
                                <br />
                                <p className='mb-3'><b>Key Highlights</b></p>
                                 <ul className=''>
                                    <li>Opportunity for entities to gain recognition and enhance their reputation</li>
                                    <li>omination for excellence across various industries</li>
                                    <li>Rigorous Selection Process</li>
                                    <li>Prestigious Awards Ceremony to Honor Winners</li>
                                    <li>PR program for discerning “Brands of Repute”</li>

                                       
                                    </ul>
                                    <div className="space-20" />
                            </div>
                        </div>
                    </div> */}
          <div className="space-50" />
        </Fragment>
      </BreadCrumb>

      <NominationForm modalOpen={popupModal} openModal={openModal} />

      <div className="space-70" />
      {/* <BannerSection/> */}
    </Fragment>
  );
};

export default BrandsPage;
