import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import { formatDate } from '../../utils/commonFunctions';
import markdownToTxt from 'markdown-to-txt';

const EntertainmentNews = ({ entertainments, data }) => {
    return (
        <Fragment>
            {data?.slice(0, 4)?.map((item, i) => (
                <div key={i} className="col-lg-6">
                    <div className="single_post post_type3 mb30">
                        <div className="post_img">
                            <div className="img_wrap">
                                <Link to={`/post/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`}>
                                    <img style={{width:350,height:272}} src={item?.attributes?.url} alt="news" />
                                </Link>
                            </div>
                        </div>
                        <div className="single_post_text">
                            <div className="meta3"><Link to={`/category/${item?.attributes?.categoryTitle?.replaceAll(" ","-")}/${item?.attributes?.category_id}`}>{item?.attributes?.categoryTitle || ""}</Link>
                                <Link to="/">{formatDate(item?.attributes?.publishedOn ? item?.attributes?.publishedOn : item?.attributes?.createdAt)} - {item?.attributes?.readingTime}</Link>
                            </div>
                            <h4><Link to={`/post/${item?.attributes?.title?.replaceAll(" ","-")}/${item?.id}`}>{item?.attributes?.title}</Link></h4>
                            <div className="space-10" />
                            <p className="post-p">{item?.attributes?.content && markdownToTxt(item?.attributes?.content)}</p>
                        </div>
                    </div>
                </div>
            ))}
        </Fragment>
    );
};

export default EntertainmentNews;