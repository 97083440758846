import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import {
  addNewsLetterAction,
  resetArticleReducer,
  addNewsLetterEmailAction,
} from "../../store/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";

const organizations = [
  "Corporate and Business Excellence",
  "Technology and Innovation",
  "Healthcare and Pharmaceuticals",
  "Finance and Banking",
  "Real Estate and Construction",
  "Consumer Goods and Retail",
  "Automotive and Transportation",
  "Media and Entertainment",
  "Energy and Utilities",
  "Education and Training",
  "Hospitality and Tourism",
  "Special Recognition Awards",
  "Marketing and Communications",
  "Manufacturing and Industry",
  "Agriculture and Food Industry",
  "Professional Services",
  "Sustainability and Environment",
  "Startup and Entrepreneurship",
  "Customer Service Excellence",
  "E-commerce and Digital Platforms",
  "Design and Architecture",
  "Fashion and Lifestyle",
  "Logistics and Supply Chain",
  "Nonprofit and Social Impact",
  "Sports and Recreation",
];
const registrationtype = [
  "Sole Proprietorship",
  "Private Limited Company",
  "Public Limited Company",
  "Limited Liability Partnership",
  "Partnership Firm",
  "Others",
];
const msmetype = [
  "Micro (Turnover <5Cr)",
  "Small (Turnover <50Cr)",
  "Medium (Turnover <250Cr)",
];

const NominationForm = ({
  newsLetterAdded,
  addNewsLetterAction,
  resetArticleReducer,
  className,
  newsLetterEmailAdded,
  addNewsLetterEmailAction,
  modalOpen,
  openModal,
}) => {
  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        required: "Required!",
        accepted: "Required!",
        email: "Enter a valid email!",
      },
      element: (message, className) => (
        <div className={`custom-error-brands ${className}`}>{message}</div>
      ),
    })
  );
  const [email, setEmail] = useState("");

  const [fields, setFields] = useState({
    registeredfirmname: "",
    registrationtype: "",
    msmetype: "",
    udayamregnumber: "",
    gstin: "",
    registeredcompanyaddress: "",
    state: "",
    city: "",
    district: "",
    pincode: "",
    websiteurl: "",
    description: "",
    contactfullname: "",
    contactemail: "",
    contactmobilenumber: "",
    referedby: "",
    nomibusinessspecialisation: "",
    terms: true,
  });
  const [, forceUpdate] = useState();

  const handleChange = (event) => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const handleClear = () => {
    // Reset form state
    setFields({
      registeredfirmname: "",
      registrationtype: "",
      msmetype: "",
      udayamregnumber: "",
      gstin: "",
      registeredcompanyaddress: "",
      state: "",
      city: "",
      district: "",
      pincode: "",
      websiteurl: "",
      description: "",
      contactfullname: "",
      contactemail: "",
      contactmobilenumber: "",
      referedby: "",
      nomibusinessspecialisation: "",
      terms: false,
    });
    // simpleValidator.current.hideMessages();
    openModal();
  };

  const handleChangecheckbox = (e) => {
    const { name, value, type, checked } = e.target;
    setFields({ ...fields, [name]: type === "checkbox" ? checked : value });
  };

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (newsLetterAdded) {
      toast.success("Submitted Successfully");
      resetArticleReducer();
    }
    if (newsLetterEmailAdded) {
      toast.success("Subscribed to Newsletter");
      setEmail("");
      resetArticleReducer();
    }
  }, [newsLetterAdded, newsLetterEmailAdded]);

  const handleSave = () => {
    const formValid = simpleValidator.current.allValid();
    console.log("saving...");
    if (!formValid) {
      console.log("form not valid...");
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    let payload = { ...fields };
    // payload["subscriptionType"] = field_type
    // handleAddData({ data: payload })
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      addNewsLetterEmailAction({ data: { email: email } });
    } else {
      toast.error("Please fill the input");
      simpleValidator.current.showMessages();
    }
  };

  return (
    <div>
      <Modal
        size="lg"
        isOpen={modalOpen}
        fade={false}
        className="nomination_popup_modal"
        backdropClassName="newsletter_popup_modal_backdrop"
      >
        <ModalBody className="modal_nomination_content" style={{ padding: 10 }}>
          <ModalHeader toggle={openModal}>{"Nominate Now"}</ModalHeader>
          <div className="mt-2">
            <form onSubmit={submitHandler}>
              <div className="brands-form p-2">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-4 mb-4">
                        <input
                          name="registeredfirmname"
                          type="text"
                          onChange={handleChange}
                          placeholder="Registered Firm Name*"
                        />
                        {simpleValidator.current.message(
                          "registeredfirmname",
                          fields?.registeredfirmname,
                          "required"
                        )}
                      </div>
                      <div className="col-lg-4 mb-4">
                        <select
                          defaultValue=""
                          class="form-select"
                          name="registrationtype"
                          onChange={handleChange}
                        >
                          <option disabled value="">
                            Registration Type*
                          </option>
                          {registrationtype?.map((value) => (
                            <option value={value}>{value}</option>
                          ))}
                        </select>
                        {simpleValidator.current.message(
                          "registrationtype",
                          fields?.registrationtype,
                          "required"
                        )}
                      </div>
                      <div className="col-lg-4 mb-4">
                        <select
                          defaultValue=""
                          class="form-select"
                          name="msmetype"
                          onChange={handleChange}
                        >
                          <option disabled value="">
                            MSME Type
                          </option>
                          {msmetype?.map((value) => (
                            <option value={value}>{value}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-lg-4 mb-4">
                        <input
                          name="udayamregnumber"
                          type="text"
                          placeholder="Udyam Registration Number"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-3 mb-4">
                        <input
                          name="gstin"
                          type="text"
                          placeholder="GSTIN*"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-5 mb-4">
                        <input
                          name="registeredcompanyaddress"
                          type="email"
                          placeholder="Registered Company Address*"
                          onChange={handleChange}
                        />
                        {simpleValidator.current.message(
                          "registeredcompanyaddress",
                          fields?.registeredcompanyaddress,
                          "required"
                        )}
                      </div>
                      <div className="col-lg-4 mb-4">
                        <input
                          name="state"
                          type="text"
                          placeholder="State*"
                          onChange={handleChange}
                        />
                        {simpleValidator.current.message(
                          "state",
                          fields?.state,
                          "required"
                        )}
                      </div>
                      <div className="col-lg-4 mb-4">
                        <input
                          name="city"
                          type="text"
                          placeholder="Village/Town/City*"
                          onChange={handleChange}
                        />
                        {simpleValidator.current.message(
                          "city",
                          fields?.city,
                          "required"
                        )}
                      </div>
                      <div className="col-lg-4 mb-4">
                        <input
                          name="district"
                          type="text"
                          placeholder="District*"
                          onChange={handleChange}
                        />

                        {simpleValidator.current.message(
                          "district",
                          fields?.district,
                          "required"
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <input
                          name="pincode"
                          type="text"
                          placeholder="Pincode*"
                          onChange={handleChange}
                        />

                        {simpleValidator.current.message(
                          "pincode",
                          fields?.pincode,
                          "required"
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <input
                          name="websiteurl"
                          type="text"
                          placeholder="Website URL*"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-4">
                        <input
                          name="description"
                          type="text"
                          placeholder="Company/Nominee Brand Description *"
                          onChange={handleChange}
                        />

                        {simpleValidator.current.message(
                          "description",
                          fields?.description,
                          "required"
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <input
                          name="contactfullname"
                          type="text"
                          placeholder="Contact Person’s Full Name*"
                          onChange={handleChange}
                        />

                        {simpleValidator.current.message(
                          "contactfullname",
                          fields?.contactfullname,
                          "required"
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <input
                          name="contactemail"
                          type="text"
                          placeholder="Email Address*"
                          onChange={handleChange}
                        />

                        {simpleValidator.current.message(
                          "contactemail",
                          fields?.contactemail,
                          "required|email"
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <input
                          name="contactmobilenumber"
                          type="text"
                          placeholder="Mobile number*"
                          onChange={handleChange}
                        />

                        {simpleValidator.current.message(
                          "mobilenumber",
                          fields?.contactmobilenumber,
                          "required"
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <input
                          name="referedby"
                          type="text"
                          placeholder="Refered by(If applicable)"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-4">
                        <select
                          defaultValue=""
                          class="form-select"
                          name="nomibusinessspecialisation"
                          onChange={handleChange}
                        >
                          <option disabled value="">
                            Nomination Business Specialization*
                          </option>
                          {organizations?.map((value) => (
                            <option value={value}>{value}</option>
                          ))}
                        </select>
                        {simpleValidator.current.message(
                          "nomibusinessspecialisation",
                          fields?.nomibusinessspecialisation,
                          "required"
                        )}
                      </div>
                    </div>
                    <div>
                      Post the qualifying approval, you will receive an email
                      for further documentation required by the Independent
                      Assessment Agency for your assessment along with the
                      prescribed assessment fee to be paid. This is fully
                      refundable should you not meet the criteria for
                      recognition as nominees/awardees. Additional award
                      participation fee will apply for the awards gala
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
        <FormGroup
          style={{ marginLeft: "15px", marginBottom: "15px" }}
          check
          inline
        >
          <Input
            type="checkbox"
            onChange={handleChangecheckbox}
            name="terms"
            onBlur={() => simpleValidator.current.showMessageFor("terms")}
          />
          <Label check>
            I have read the above and I am aware there is an assessment fee and
            event participation fee
          </Label>
        </FormGroup>
        {simpleValidator.current.message("terms", fields.terms, "accepted", {
          className: "custom-error-checkbox-brands",
        })}
        <ModalFooter>
          <Button color="secondary" onClick={() => handleClear()}>
            Cancel
          </Button>
          <Button
            style={{ background: "green" }}
            type="submit"
            onClick={handleSave}
          >
            Submit
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ articlesReducer }) => {
  const { newsLetterAdded, newsLetterEmailAdded } = articlesReducer;
  return { newsLetterAdded, newsLetterEmailAdded };
};

const mapActionsToProps = {
  addNewsLetterAction,
  resetArticleReducer,
  addNewsLetterEmailAction,
};

export default connect(mapStateToProps, mapActionsToProps)(NominationForm);
