import React, { Fragment, useState, useEffect } from 'react';
import FontAwesome from "../uiStyle/FontAwesome";
import tempIcon from '../../doc/img/icon/temp.png';
import { Link, NavLink, useLocation } from "react-router-dom";
import SearchModal from "../SearchModal";
import SidebarMenu from "../SidebarMenu";
import { getLocationAction, loadCategoriesAction, getWeatherInfoAction } from '../../store/actions';
import { connect } from 'react-redux';

const menus = [
    {
        id: 1,
        linkText: 'Home',
        link: '/'
    },
    {
        id: 2,
        linkText: 'About',
        link: '/about'
    },
    {
        id: 3,
        linkText: 'Repute',
        link: '/sports',
        child: true,
        icon: 'angle-down',
        submenu: []
    },
    {
        id: 4,
        linkText: 'Contact',
        link: '/contact'
    },
    {
        id: 5,
        linkText: 'Partner with Us',
        link: '/partner-with-us'
    },
    {
        id: 5,
        linkText: 'Brands of Repute - Nominations',
        link: '/brands'
    },
];
const menusDark = [
    {
        id: 1,
        linkText: 'Home',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 11,
                link: '/',
                linkText: 'Home 1'
            },
            {
                id: 12,
                link: '/dark',
                linkText: 'Home Dark'
            },
            {
                id: 13,
                new: true,
                link: '/home-two',
                linkText: 'Home 2'
            },
            {
                id: 14,
                link: '/home-three',
                linkText: 'Home 3'
            },
        ]
    },
    {
        id: 2,
        linkText: 'Pages',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 21,
                link: '/dark/about',
                linkText: 'About'
            },
            {
                id: 22,
                link: '/dark/archive',
                linkText: 'Archive'
            },
            {
                id: 23,
                link: '/dark/contact',
                linkText: 'Contact Us'
            },
            {
                id: 24,
                link: '/dark/404',
                linkText: '404'
            },
        ]
    },
    {
        id: 3,
        linkText: 'Posts',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 31,
                child: true,
                linkText: 'General Posts',
                third_menu: [
                    {
                        id: 311,
                        link: '/dark/post1',
                        linkText: 'Post 1',
                    },
                    {
                        id: 312,
                        link: '/dark/post2',
                        linkText: 'Post 2',
                    },
                    {
                        id: 313,
                        link: '/dark/post3',
                        linkText: 'Post 3',
                    },
                ],
            },
            {
                id: 32,
                child: true,
                linkText: 'Video Posts',
                third_menu: [
                    {
                        id: 321,
                        link: '/dark/video_post1',
                        linkText: 'Video Style 1',
                    },
                    {
                        id: 322,
                        link: '/dark/video_post2',
                        linkText: 'Video Style 2',
                    },
                    {
                        id: 323,
                        link: '/dark/video_post3',
                        linkText: 'Video Style 3',
                    },
                ],
            },
            {
                id: 33,
                child: true,
                linkText: 'Audio Posts',
                third_menu: [
                    {
                        id: 331,
                        link: '/dark/audio_post1',
                        linkText: 'Audio Style 1',
                    },
                    {
                        id: 332,
                        link: '/dark/audio_post2',
                        linkText: 'Audio Style 2',
                    },
                    {
                        id: 333,
                        link: '/dark/audio_post3',
                        linkText: 'Audio Style 3',
                    },
                ],
            },
            {
                id: 34,
                child: true,
                linkText: 'Sidebars',
                third_menu: [
                    {
                        id: 341,
                        link: '/dark/post1',
                        linkText: 'Right Sidebar',
                    },
                    {
                        id: 342,
                        link: '/dark/left_post2',
                        linkText: 'Left Sidebar',
                    },
                    {
                        id: 343,
                        link: '/dark/post2',
                        linkText: 'No Sidebar',
                    },
                ],
            },
        ]
    },
    {
        id: 4,
        linkText: 'Categories',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 41,
                link: '/dark/business',
                linkText: 'Business'
            },
            {
                id: 42,
                link: '/dark/entertainment',
                linkText: 'Entertainment'
            },
            {
                id: 43,
                link: '/dark/features',
                linkText: 'Features'
            },
            {
                id: 44,
                link: '/dark/sports',
                linkText: 'Sports'
            },
            {
                id: 45,
                link: '/dark/trending',
                linkText: 'Trending'
            },
        ]
    },
    {
        id: 5,
        linkText: 'World',
        link: '/dark/world'
    },
    {
        id: 6,
        linkText: 'Sports',
        link: '/dark/sports'
    },
    {
        id: 7,
        linkText: 'Contact',
        link: '/dark/contact'
    },
];

const MainMenu = ({ className, dark, getLocationAction, location, loadCategoriesAction, categories, getWeatherInfoAction }) => {
    const locationRouter = useLocation();
    const [searchShow, setSearchShow] = useState(false);
    const [sideShow, setSideShow] = useState(false);
    const [menuoptions, setMenuoptions] = useState(menus);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);


    const arr = dark ? menusDark : menuoptions;

    useEffect(() => {
        loadCategories()
        getLocation();
    }, [])

    const getLocation = async () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setLatitude(position.coords.latitude)
            setLongitude(position.coords.longitude)
        });
    };

    useEffect(() => {
        if (latitude && longitude) {
            getWeatherInfoAction(`${latitude},${longitude}`)
        }
    }, [latitude, longitude])

    useEffect(() => {
        console.log("categores", categories)
        if (categories?.length) {
            console.log("categores", categories)
            let men = [...menus]
            let arr = []
            men[2].submenu = categories?.filter((e)=> e.attributes.title !== "Trending stories").map((item, i) => ({ id: i + 1, link: `/category/${item?.attributes?.title?.replaceAll(" ", "-")}/${item?.id}`, linkText: item?.attributes?.title }))
            setMenuoptions(men)
        }
    }, [categories])

    const loadCategories = () => {
        loadCategoriesAction()
    }

    function splitArrayBy5(array) {
        const chunks = [];
        for (let i = 0; i < array.length; i += 7) {
            chunks.push(array.slice(i, i + 7));
        }
        return chunks;
    }



    return (
        <Fragment>
            <div className={`main-menu ${className ? className : ''}`} id="header">
                <Link to="#top" className="up_btn up_btn1">
                    <FontAwesome name="chevron-double-up" />
                </Link>
                <div className="main-nav clearfix is-ts-sticky">
                    <div className="container">
                        <div className="row justify-content-between">
                            <nav className="navbar navbar-expand-lg col-lg-10 align-self-center">
                                <div className="site-nav-inner">
                                    <button className="navbar-toggler" onClick={() => setSideShow(true)}>
                                        <FontAwesome name="bars" />
                                    </button>
                                    <div id="navbarSupportedContent"
                                        className="collapse navbar-collapse navbar-responsive-collapse">
                                        <ul className="nav navbar-nav" id="scroll">
                                            {arr.length > 0 ? arr.map((item, i) => (
                                                <li key={i}
                                                    className={`${item.child ? 'dropdown' : ''}${locationRouter.pathname === item.link && "selected"} nav-item`}>
                                                    {item.child ? <NavLink onClick={e => e.preventDefault()} to="/"
                                                        className="menu-dropdown"
                                                        data-toggle="dropdown">{item.linkText}
                                                        <FontAwesome
                                                            name={item.icon} /></NavLink>
                                                        : <NavLink to={item.link} className="menu-dropdown"
                                                            data-toggle="dropdown">{item.linkText} <FontAwesome
                                                                name={item.icon} /></NavLink>}

                                                    {item.child ?
                                                        <ul className="dropdown-menu" role="menu">
                                                            {splitArrayBy5(item.submenu)?.map((splitItem, index, arr) => (
                                                                <Fragment>
                                                                    <div style={{ width: 250 }}>
                                                                        {splitItem?.map((sub_item, i) => (
                                                                            <li key={i}
                                                                                className={`${sub_item.child ? 'dropdown-submenu' : null}
                                                        `}>
                                                                                {sub_item.child ?
                                                                                    <NavLink onClick={e => e.preventDefault()}
                                                                                        to="/">{sub_item.linkText}</NavLink>
                                                                                    : <NavLink
                                                                                        to={sub_item.link}>{sub_item.linkText}</NavLink>}
                                                                                {sub_item.third_menu ?
                                                                                    <ul className="dropdown-menu">
                                                                                        {sub_item.third_menu.map((third_item, i) => (
                                                                                            <li key={i}><NavLink
                                                                                                to={third_item.link}>{third_item.linkText}</NavLink>
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul> : null}
                                                                            </li>
                                                                        ))}
                                                                    </div>
                                                                    {index !== arr?.length - 1 && <div style={{ height: "auto", backgroundColor: 'rgb(231, 235, 240)', width: 1 }}></div>}
                                                                </Fragment>
                                                            ))}

                                                        </ul>
                                                        : null
                                                    }
                                                </li>
                                            )) : null}
                                        </ul>
                                    </div>
                                    <SidebarMenu sideShow={sideShow} setSideShow={setSideShow} menus={arr} />
                                </div>
                            </nav>
                            <div className="col-lg-2 align-self-center">
                                <div className="menu_right">
                                    <div className="users_area">
                                        <ul className="inline">
                                            {/* <li className="search_btn" onClick={() => setSearchShow(!searchShow)}>
                                                <FontAwesome name="search" /></li> */}
                                            {/* <li><FontAwesome name="user-circle" /></li> */}
                                        </ul>
                                    </div>
                                    {/* <div className="lang d-none d-xl-block">
                                        <ul>
                                            <li><Link to="/">English</Link>
                                                <ul>
                                                    <li><Link to="/">Spanish</Link>
                                                    </li>
                                                    <li><Link to="/">China</Link>
                                                    </li>
                                                    <li><Link to="/">Hindi</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div> */}
                                   {location?.location?.name && <div className="temp d-none d-lg-block">
                                        <div className="temp_wap">
                                            <div className="temp_icon">
                                                <img src={location?.current?.condition?.icon} alt="temp icon" style={{ width: 40 }} />
                                            </div>
                                            <h3 className="temp_count">{location?.current?.temp_c?.toFixed(0)} °C</h3>
                                            <p>{location?.location?.name}</p>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {searchShow ?
                <SearchModal setSearchShow={setSearchShow} searchShow={searchShow} />
                : null
            }
        </Fragment>
    );
};

const mapStateToProps = ({ meta, articlesReducer }) => {
    const { location } = meta
    const { categories } = articlesReducer
    return { location, categories }
}

const mapActionsToProps = {
    getLocationAction,
    loadCategoriesAction,
    getWeatherInfoAction
};

export default connect(mapStateToProps, mapActionsToProps)(MainMenu);